<template>
  <div id="ProductScreen">
    <v-container>
      <v-row>
        <v-col class="col-12 col-lg-9">
          <product-list></product-list>
        </v-col>
        <v-col class="col-12 col-lg-3">
          <v-card>
            <v-app-bar dense color="transparent">
              <v-toolbar-title>
                <h5>Masukkan Pencarian</h5>
              </v-toolbar-title>
            </v-app-bar>
            <v-card-text>
              <v-form v-model="valid" ref="form" lazy-validation>
                <v-text-field
                  dense
                  outlined
                  label="Nama Produk"
                  v-model="product.productName"
                  clearable
                ></v-text-field>
                <v-select
                  dense
                  outlined
                  v-model="product.productCategory"
                  :items="getSupplierCategories"
                  item-text="category_name"
                  item-value="id"
                  menu-props="auto"
                  label="Kategori"
                  clearable
                ></v-select>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                block
                color="primary"
                @click="onSearchClick"
                :loading="getLoading"
                :disabled="getLoading"
                >Cari</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ProductList from "@/components/Widget/supplier/ProductList";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    ProductList
  },

  data() {
    return {
      valid: true,
      product: {}
    };
  },

  computed: {
    ...mapGetters(["getLoading"]),
    ...mapGetters("product", ["getSupplierCategories"])
  },

  mounted() {
    this.getSupplierProducts();
  },

  methods: {
    ...mapActions("product", ["getSupplierProducts"]),

    onSearchClick() {
      let params = {
        product_name: this.product.productName || "",
        category_id: this.product.productCategory || ""
      };

      this.$store.dispatch("product/getProductBySearch", params).catch(() =>
        this.$tostini({
          message: this.$store.state.message,
          type: "error"
        })
      );
    }
  }
};
</script>

<template>
  <div>
    <v-card>
      <v-app-bar dense color="transparent">
        <v-toolbar-title>
          <h5>Daftar Produk</h5>
        </v-toolbar-title>
        <v-spacer />
        <v-icon @click="refreshData">mdi-refresh</v-icon>
      </v-app-bar>

      <v-card-text class="pa-0">
        <template>
          <v-data-table
            :headers="headers"
            :items="getSupplierProducts"
            :loading="getLoading"
            loading-text="Sedang Menyiapkan Data..."
            mobile-breakpoint="0"
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td>{{ props.item.product_name }}</td>
                <td>{{ props.item.product_code }}</td>
                <td>{{ formatAmount(props.item.stock) }}</td>
                <td>{{ formatRupiah(props.item.order_price) }}</td>
                <td>{{ props.item.category_name }}</td>
                <td>{{ props.item.unit }}</td>
                <td>
                  <v-btn
                    color="success"
                    class="ma-2 white--text"
                    @click="showDialog(props.item)"
                    small
                  >
                    Ubah
                    <v-icon right small>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" max-width="800">
      <v-card class="mx-auto" outlined>
        <v-app-bar dense color="transparent">
          <v-toolbar-title>
            <h5>Ubah Produk</h5>
          </v-toolbar-title>
        </v-app-bar>

        <v-card-text>
          <v-form v-model="valid" ref="form" lazy-validation class="mt-5">
            <v-row>
              <v-col class="col-3">Nama Produk</v-col>
              <v-col class="col-9">
                <v-text-field
                  dense
                  v-model="newItem.product_name"
                  outlined
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-3">SKU</v-col>
              <v-col class="col-9">
                <v-text-field
                  dense
                  v-model="newItem.product_code"
                  outlined
                  clearable
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-3">Persediaan</v-col>
              <v-col class="col-9">
                <v-text-field
                  dense
                  v-model="newItem.stock"
                  outlined
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-3">Harga Jual</v-col>
              <v-col class="col-9">
                <div>
                  <vuetify-money
                    dense
                    v-model="newItem.order_price"
                    outlined
                    clearable
                    v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                    v-bind:options="options"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-3">Kategori</v-col>
              <v-col class="col-9">
                <v-text-field
                  dense
                  v-model="newItem.category_name"
                  outlined
                  clearable
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-3">Satuan</v-col>
              <v-col class="col-9">
                <v-text-field
                  dense
                  v-model="newItem.unit"
                  outlined
                  clearable
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-divider></v-divider>
          <v-card-actions>
            <v-row>
              <v-col class="d-flex justify-end">
                <v-btn
                  class="mr-5"
                  color="error"
                  :disabled="getLoading"
                  @click="onCloseClick"
                  text
                  >Batal</v-btn
                >
                <v-btn
                  color="success"
                  :loading="getLoading"
                  :disabled="getLoading"
                  @click="onProductChangeClick"
                  >Submit</v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import globalMixin from "@/mixins/globalMixin.js";
import { mapGetters } from "vuex";

export default {
  mixins: [globalMixin],

  data() {
    return {
      dialog: false,
      valid: true,
      headers: [
        { text: "Nama Produk", sortable: false },
        { text: "SKU", sortable: false },
        { text: "Persediaan", sortable: false },
        { text: "Harga Jual", sortable: false },
        { text: "Kategori", sortable: false },
        { text: "Satuan", sortable: false },
        { text: "Aksi", sortable: false }
      ],
      newItem: {},
      oldItem: {},
      valueWhenIsEmpty: "",
      options: {
        locale: "pt-BR",
        prefix: "Rp. ",
        suffix: "",
        precision: 0
      }
    };
  },

  watch: {
    dialog(val) {
      val || this.onCloseClick();
    }
  },

  computed: {
    ...mapGetters(["getLoading"]),
    ...mapGetters("product", ["getSupplierProducts"])
  },

  methods: {
    showDialog(item) {
      this.newItem = Object.assign({}, item);
      this.dialog = true;
    },
    onCloseClick() {
      this.dialog = false;
      this.$nextTick(() => {
        this.newItem = Object.assign({}, this.oldItem);
      });
    },
    onProductChangeClick() {
      const payload = {
        id: this.newItem.id,
        product_name: this.newItem.product_name,
        stock: this.newItem.stock,
        order_price: this.newItem.order_price
      };

      this.$store
        .dispatch("product/updateProductData", payload)
        .then(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "success"
          });
        })
        .catch(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "error"
          });
        })
        .finally(() => {
          this.refreshData();
          this.dialog = false;
        });
    },
    refreshData() {
      this.$store.dispatch("product/getSupplierProducts");
    }
  }
};
</script>
